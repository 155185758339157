import React, { useEffect, useState } from "react";

import {
    _guiYeuCauBaoHanh,
    _checkBaoHanh,
    _addBaoHanh,
} from "../../api/baoHanh";

import { _kichHoatBaoHanh, _getBaoHanh } from "../../api/baoHanh";
import { useSelector } from "react-redux";

const Loading = ({ children }) => {
    const loading = useSelector((store) => store.loading.isloading);
    const [isloading, setIsLoading] = useState(loading)

    useEffect(() => {
        setIsLoading(loading)
    }, [loading]);

    return (
        <> 

            <div style={{position:'relative'}}>
                <div style={{overflow: 'hidden', position: 'absolute',display: isloading ? 'flex' : 'none',justifyContent:"center",alignItems:"center",backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex:100000000,width:'100%',height:'100%'}}>
                <span class="loader"></span>

                </div>
                {children}
            </div>

        </>
    );
};

export default Loading;
