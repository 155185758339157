export function formatDate(inputDate) {
    if(inputDate == "") { return "" }
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const year = date.getFullYear();
    
    return `${day}-${month}-${year}`;
    
  }

export function compareDatewithNow(inputDate){
    const [day, month, year] = inputDate.split('-').map(Number);
   
    const givenDate = new Date(year, month - 1, day);

    const currentDate = new Date();
    
    
    currentDate.setHours(0, 0, 0, 0);
  
 
  
   
    return givenDate < currentDate;
}