import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setCaptcha } from '../../redux/loading/action';

const Captcha = ({ value }) => {
  const [capcha, setcapcha] = useState(false);
  const dispatch = useDispatch();
  const checkCaptcha = () => {
    dispatch(setCaptcha(true));
    setcapcha(true)
  };

  useEffect(() => {
    return () => {
      dispatch(setCaptcha(false));
    setcapcha(false)
    };
  }, [value]);

 
  return (
    <div style={{display:'flex',alignItems:'center'}}>
    <div className="captcha" style={{width:'100%'}}>
      <div className="spinner" style={{ fontSize: '15px' }}>
        <label>
          <input type="checkbox" className='checkbox-captcha' checked = {capcha == true} onChange={checkCaptcha} />
          <span className="checkmark">
            <span>&nbsp;</span>
          </span>
        </label>
      </div>
      <div className="text" style={{ fontSize: '15px' }}>I'm not a robot</div>
      <div className="logo" style={{ fontSize: '9px' }}>
        <img src="https://forum.nox.tv/core/index.php?media/9-recaptcha-png/" />
      
      </div>
    </div>

  </div>
  )
}

export default Captcha