import React, { useEffect, useState } from "react";

import { Table } from "reactstrap";

import { _traCuuBaoHanh } from "../../api/baoHanh";
import { LockOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { DatePicker, Pagination, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  DoiMatKhau,
  getDanhSachPhieuBaoHanh,
  getDemDanhSachPhieuBaoHanh,
  login,
} from "../../redux/category/action";

import { Modal, Form, Input } from "antd";
import { setLoading } from "../../redux/loading/action";
import moment from "moment";
import YeuCauDetail from "./yeucaudetail";

import { formatCurrency } from "../../helper/formatNumber";

const TraCuuTinhTrangBaoHanh = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [maKH, setMaKH] = useState("");
  const [sotrang, setSoTrang] = useState(1);
  const [sobaomat, setSoBaoMat] = useState("");
  const [startDate, setStartDate] = useState("01-07-2024");
  const [soluong, setSoLuong] = useState(10);
  const [isTracuuDetail, setIsTraCuuDetail] = useState(false);
  const [serial, setSerial] = useState("");
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [enddate, setEndDate] = useState("");

  const [sochungtu, setSoChungTu] = useState("");


  const danhsachphieubaohanh = useSelector(
    (store) => store.categories.getDanhSachPhieuBaoHanh
  );
  const demdanhsachphieubaohanh = useSelector(
    (store) => store.categories.getDemDanhSachPhieuBaoHanh
  );

  const [tinhtrang, setTinhTrang] = useState("");
  const changepassword = useSelector((store) => store.categories.DoiMatKhau);
  const loginWidthDaily = useSelector((store) => store.categories.login);
  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];

  const Login = async () => {
    const params = {
      makhachhang: maKH,
      maso: sobaomat,
    };
    await dispatch(setLoading(true));
    const response = await dispatch(login(params));

    await dispatch(setLoading(false));
  };

  const onClick = async () => {
    const params = {
      makhachhang: maKH,
      maso: sobaomat,
      serial: serial,
      tungay: startDate,
      denngay: enddate,
      sotrang: sotrang,
      soluong: soluong,
    };

    await dispatch(setLoading(true));
    const response = await dispatch(getDemDanhSachPhieuBaoHanh(params));

    if (response?.statuscode == true) {
      await dispatch(getDanhSachPhieuBaoHanh(params));
    }

    await dispatch(setLoading(false));
  };

  const onShowSizeChange = async (current, pageSize) => {
    console.log(sotrang, soluong);

    await setSoLuong(pageSize);
    const params = {
      makhachhang: maKH,
      maso: sobaomat,
      serial: serial,
      tungay: startDate,
      denngay: enddate,
      sotrang: sotrang,
      soluong: pageSize,
    };

    await dispatch(setLoading(true));

    await dispatch(getDanhSachPhieuBaoHanh(params));

    await dispatch(getDemDanhSachPhieuBaoHanh(params));
    await dispatch(setLoading(false));
  };

  const handlePageChange = async (page, pageSize) => {
    setSoTrang(page);
    const params = {
      makhachhang: maKH,
      maso: sobaomat,
      serial: serial,
      tungay: startDate,
      denngay: enddate,
      sotrang: page,
      soluong: pageSize,
    };

    await dispatch(setLoading(true));

    await dispatch(getDanhSachPhieuBaoHanh(params));

    await dispatch(getDemDanhSachPhieuBaoHanh(params));
    await dispatch(setLoading(false));
  };

  const onChangeStartDate = (date, dateString) => {
    setStartDate(dateString);
  };
  const onChangeEndDate = (date, dateString) => {
    setEndDate(dateString);
  };
  const navigateToYeuCauDetail = (id) => {
    setIsTraCuuDetail(true);
    setSoChungTu(id);
    // history.push(`/gui-yeu-cau/${id}`);
  };

  const navigateToTraCuuDetail = (sdt, sochungtu) => {
    // setIsTraCuuDetail(true);
    // setSoChungTu(id);

    history.push(`/tra-cuu-bao-hanh-detail/${sdt}/${sochungtu}`);
  };

  const handleOkDetail = () => {
    setIsTraCuuDetail(false);
  };
  const handleCancelDetail = () => {
    setIsTraCuuDetail(false);
  };

  const Refresh = async () => {
    await onClick();
  };

  const disabledDate = (current) => {
    const startDate = moment("2024-07-01");
    const today = moment().endOf("day");

    return current && (current < startDate || current > today);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    const values = await form.validateFields();
    console.log(values);
    const body = {
      makhachhang: maKH,
      maso: values.passwordcurrent,
      masomoi: values.passwordnew,
    };
    if (values.passwordnew != values.passwordrepeat) {
      toast.error("Mật khẩu nhập lại không khớp với mật khẩu mới");
    } else {
      await dispatch(DoiMatKhau(body));
    }
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };



  return (
    <div className="container" style={{ marginTop: "30px" }}>
      <div className="content h-100 align-middle ">
        <div className="title-content text-center mb-3">
          {loginWidthDaily?.tenkhachhang ? (
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div className="mb-4 mt-2 d-flex justify-content-center col-md-6 welcome-title">
                  <div className="four">
                    <h1 className="title-welcome">
                      <span>Chào mừng</span>{" "}
                      {loginWidthDaily?.tenkhachhang ?? ""}
                    </h1>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <h3 className="text-uppercase fw-bold">
                TRA CỨU TÌNH TRẠNG BẢO HÀNH - SỬA CHỮA
              </h3>
              <h6 className="fw-bold">Dành cho khách hàng đại lý</h6>
            </>
          )}
        </div>

        <div
          className="row"
          style={{
            marginRight: "20%",
            marginLeft: "20%",
            display: "flex",
            alignItems: "center",
          }}
        >
          {!loginWidthDaily?.tenkhachhang && (
            <>
              <div className="mb-4 mt-2 d-flex justify-content-center col-md-4">
                <input
                  className="form-control w-full"
                  placeholder="Nhập mã KH"
                  required
                  value={maKH}
                  onChange={(e) => setMaKH(e.target.value)}
                />
              </div>
              <div className="mb-4 mt-2 d-flex justify-content-center col-md-4">
                <input
                  className="form-control w-full "
                  type="password"
                  required
                  placeholder="Nhập số bảo mật"
                  value={sobaomat}
                  onChange={(e) => setSoBaoMat(e.target.value)}
                />
              </div>
            </>
          )}
          {loginWidthDaily?.tenkhachhang ? (
            <>
              <div className="mb-4 mt-2 d-flex justify-content-center col-md-6">
                <button className="giga-kich-hoat-btn" onClick={showModal}>
                  Đổi mật khẩu
                </button>
              </div>
            </>
          ) : (
            <div className="mb-4 mt-2 d-flex justify-content-center col-md-2">
              <button className="giga-kich-hoat-btn" onClick={Login}>
                Đăng nhập
              </button>
            </div>
          )}
          <div className="mb-4 mt-2 d-flex justify-content-center col-md-2">
            <button className="giga-kich-hoat-btn" onClick={() => Refresh()}>
              Làm mới
            </button>
          </div>
        </div>
        {loginWidthDaily?.tenkhachhang && (
          <>
            <div className="row">
              <div className="col-md-12">
                <div
                  className="daily_question col-md-1"
                  style={{ display: "inline-block", fontWeight: "600" }}
                >
                  Tên khách hàng:{" "}
                </div>{" "}
                {loginWidthDaily?.tenkhachhang && (
                  <span>{loginWidthDaily?.tenkhachhang ?? ""}</span>
                )}
              </div>{" "}
              <div className="col-md-12 my-3" style={{ display: 'flex', alignItems: 'center' }}>

                <div
                  className="daily_question col-md-1"
                  style={{ display: "flex", fontWeight: "600" }}
                >
                  Nhập số serial:{" "}
                </div>{" "}
                <input
                  className='form-control mr-2' style={{ width: '30%' }}
                  placeholder='Nhập mã số serial'
                  value={serial} onChange={(e) => setSerial(e.target.value)}
                />
              </div>{" "}
              <br /> <br />
              <div className="col-md-12 " style={{ display: 'flex', alignItems: 'center' }}>

                    <div className="daily_question col-md-1"   style={{ display: "flex", fontWeight: "600" }}>
                      Từ ngày:
                    </div>
                    <div className="col-md-2">
                      <DatePicker
                        onChange={onChangeStartDate}
                        name="startdate"
                        picker="startDate"
                        format={dateFormatList}
                        disabledDate={disabledDate}
                      />
                    </div>

                  <div
                   className="daily_question col-md-1"   style={{ display: "flex", fontWeight: "600" }}
                  > 
                      Đến ngày: </div>
                    <div className="col-md-2">
                      <DatePicker
                        onChange={onChangeEndDate}
                        name="enddate"
                        picker="endDate"
                        format={dateFormatList}
                        disabledDate={disabledDate}
                      />
                    </div>
                  
              

                <div className="col-md-2">
                  <button className="giga-kich-hoat-btn " onClick={onClick}>
                    Tìm kiếm
                  </button>
                </div>
              </div>
            </div>
            <br />

            <div className="row">
              <div className="col-md-8" style={{ display: "flex" }}>
                <div className="col-md-2 tab_tracuubaohanh">
                  <div onClick={() => setTinhTrang("Tất cả phiếu")} className="cursor-pointer">
                    Tất cả phiếu(
                    {demdanhsachphieubaohanh?.datas?.TAT_CA_PHIEU ?? 0})
                  </div>
                </div>
                <div className="col-md-2 tab_tracuubaohanh">
                  <div onClick={() => setTinhTrang("Phiếu mới tạo")} className="cursor-pointer">
                    Phiếu mới tạo(
                    {demdanhsachphieubaohanh?.datas?.PHIEU_MOI_TAO ?? 0})
                  </div>
                </div>
                <div className="col-md-2 tab_tracuubaohanh">
                  <div onClick={() => setTinhTrang("Đang sửa chữa")} className="cursor-pointer">
                    Đang sửa chữa(
                    {demdanhsachphieubaohanh?.datas?.DANG_SUA_CHUA ?? 0})
                  </div>
                </div>
                <div className="col-md-2 tab_tracuubaohanh">
                  <div onClick={() => setTinhTrang("Đang chuyển trả")} className="cursor-pointer">
                    Đang chuyển trả(
                    {demdanhsachphieubaohanh?.datas?.DANG_CHUYEN_TRA ?? 0})
                  </div>
                </div>
                <div className="col-md-2 tab_tracuubaohanh">
                  <div onClick={() => setTinhTrang("Hoàn tất")} className="cursor-pointer">
                    Hoàn tất({demdanhsachphieubaohanh?.datas?.HOAN_TAT ?? 0})
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-12">
                <div
                  className="table-responsive"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "150vh",
                    overflow: "auto",
                  }}
                >
                  <Table bordered>
                    <thead>
                      <tr className="">
                        <th className="text-center bg-giga text-white">STT</th>
                        <th className="text-center bg-giga text-white">
                          Ngày tạo yêu cầu
                        </th>
                        <th className="text-center bg-giga text-white">
                          Mã số yêu cầu
                        </th>
                        <th className="text-center bg-giga text-white">
                          Tên sản phẩm
                        </th>
                        <th className="text-center bg-giga text-white">
                          Serial
                        </th>
                        <th className="text-center bg-giga text-white">
                          Phí dịch vụ
                        </th>
                        <th className="text-center bg-giga text-white">
                          Trạng thái
                        </th>
                        <th className="text-center bg-giga text-white">
                          Ghi chú
                        </th>
                        <th className="text-center bg-giga text-white">
                          Ngày cập nhật
                        </th>
                        <th className="text-center bg-giga text-white">
                          Xem phiếu
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {!danhsachphieubaohanh?.datas ? (
                        <tr colspan="9">
                          <div> Chưa có dữ liệu</div>
                        </tr>
                      ) : (
                        <>
                          {tinhtrang == "" || tinhtrang == "Tất cả phiếu" ? (
                            <>
                              {danhsachphieubaohanh?.datas?.map((item, index) => (
                                <tr key={item.id} id="tra-cuu-phieu-bao-hanh-tr">
                                  {/* <th scope="row" className='middle'> {moment(item.thoiGian).format('DD/MM/YYYY HH:mm')}</th> */}
                                  <td
                                    className="middle"
                                    style={{
                                      whiteSpace: "pre-line",
                                      textAlign: "center",
                                    }}
                                  >
                                    {item.index}
                                  </td>
                                  <td className="middle">
                                    {item?.NGAY_TAO_YEU_CAU}
                                  </td>
                                  <td
                                    className="middle pointer"
                                    style={{ whiteSpace: "pre-line", cursor: 'pointer' }}

                                  >
                                    <a target="_blank" rel="noreferrer" href={`/tra-cuu-bao-hanh-detail/${item?.SDT_KH}/${item?.SO_CHUNG_TU}`}>   {item?.SO_CHUNG_TU}</a>


                                  </td>
                                  <td
                                    className="middle hover_sanpham"
                                    style={{ whiteSpace: "pre-line", cursor: 'pointer' }}

                                  >
                                    <a target="_blank" rel="noreferrer" href={`/tra-cuu-bao-hanh-detail/${item?.SDT_KH}/${item?.SO_CHUNG_TU}`}>  {item?.TEN_SAN_PHAM} </a>
                                  </td>
                                  <td
                                    className="middle"
                                    style={{ whiteSpace: "pre-line", cursor: 'pointer' }}

                                  >
                                    <a target="_blank" rel="noreferrer" href={`/tra-cuu-bao-hanh-detail/${item?.SDT_KH}/${item?.SO_CHUNG_TU}`}>  {item?.SERIAL} </a>
                                  </td>
                                  <td
                                    className="middle"
                                    style={{ whiteSpace: "pre-line" }}
                                  >
                                    {formatCurrency(item?.CHI_PHI_DICH_VU) ?? 0}
                                  </td>
                                  <td
                                    className="middle"
                                    style={{ whiteSpace: "pre-line" }}
                                  >
                                    {item?.TRANG_THAI}
                                  </td>
                                  <td
                                    className="middle"
                                    style={{ whiteSpace: "pre-line" }}
                                  >
                                    {item?.GHI_CHU_TOI_KHACH_HANG}
                                  </td>
                                  <td
                                    className="middle"
                                    style={{ whiteSpace: "pre-line" }}
                                  >
                                    {item?.NGAY_CHUYEN_TRANG_THAI}
                                  </td>
                                  {item?.VIEW_CHI_TIET == 1 ? (
                                    <td
                                      className="middle"
                                      style={{
                                        whiteSpace: "pre-line",
                                        textAlign: "center",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        navigateToYeuCauDetail(item?.SO_CHUNG_TU)
                                      }
                                    >
                                      <SearchOutlined />
                                    </td>
                                  ) : (
                                    <td className="middle"></td>
                                  )}
                                </tr>
                              ))}
                            </>

                          ) : (
                            <>
                              {danhsachphieubaohanh?.datas?.filter(item => item.Filters == tinhtrang)?.map((item, index) => (
                                <tr key={item.id}>
                                  {/* <th scope="row" className='middle'> {moment(item.thoiGian).format('DD/MM/YYYY HH:mm')}</th> */}
                                  <td
                                    className="middle"
                                    style={{
                                      whiteSpace: "pre-line",
                                      textAlign: "center",
                                    }}
                                  >
                                    {item.index}
                                  </td>
                                  <td className="middle">
                                    {item?.NGAY_TAO_YEU_CAU}
                                  </td>
                                  <td
                                    className="middle"
                                    style={{ whiteSpace: "pre-line" }}
                                  >
                                    {item?.SO_CHUNG_TU}
                                  </td>
                                  <td
                                    className="middle"
                                    style={{ whiteSpace: "pre-line" }}
                                  >
                                    {item?.TEN_SAN_PHAM}
                                  </td>
                                  <td
                                    className="middle"
                                    style={{ whiteSpace: "pre-line" }}
                                  >
                                    {item?.SERIAL}
                                  </td>
                                  <td
                                    className="middle"
                                    style={{ whiteSpace: "pre-line" }}
                                  >
                                    {formatCurrency(item?.CHI_PHI_DICH_VU) ?? 0}
                                  </td>
                                  <td
                                    className="middle"
                                    style={{ whiteSpace: "pre-line" }}
                                  >
                                    {item?.TRANG_THAI}
                                  </td>
                                  <td
                                    className="middle"
                                    style={{ whiteSpace: "pre-line" }}
                                  >
                                    {item?.GHI_CHU_TOI_KHACH_HANG}
                                  </td>
                                  <td
                                    className="middle"
                                    style={{ whiteSpace: "pre-line" }}
                                  >
                                    {item?.NGAY_CHUYEN_TRANG_THAI}
                                  </td>
                                  {item?.VIEW_CHI_TIET == 1 ? (
                                    <td
                                      className="middle"
                                      style={{
                                        whiteSpace: "pre-line",
                                        textAlign: "center",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        navigateToYeuCauDetail(item?.SO_CHUNG_TU)
                                      }
                                    >
                                      <SearchOutlined />
                                    </td>
                                  ) : (
                                    <td className="middle"></td>
                                  )}
                                </tr>
                              ))}
                            </>
                          )}

                        </>
                      )}
                    </tbody>
                  </Table>
                </div>
                <div className="pagination mb-5">
                  <div className="so_trang_pagination">
                    <Pagination
                      showSizeChanger
                      onShowSizeChange={onShowSizeChange}
                      defaultCurrent={1}
                      onChange={handlePageChange}
                      total={demdanhsachphieubaohanh?.datas?.TAT_CA_PHIEU}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Modal
        title="Đổi mật khẩu"
        okText="Đổi mật khẩu"
        open={isModalOpen}
        width={1000}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} name="normal_login" className="login-form">
          <label className="mb-2">Vui lòng nhập mật khẩu hiện tại</label>
          <Form.Item
            name="passwordcurrent"
            rules={[
              { required: true, message: "Vui lòng nhập mật khẩu hiện tại!" },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              name="passwordcurrent"
              placeholder="Vui lòng nhập mật khẩu hiện tại!"
            />
          </Form.Item>
          <label className="mb-2">Vui lòng nhập mật khẩu mới!</label>
          <Form.Item
            name="passwordnew"
            rules={[{ required: true, message: "Vui lòng nhập mật khẩu mới!" }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Vui lòng nhập mật khẩu mới!"
            />
          </Form.Item>
          <label className="mb-2">Xác nhận lại mật khẩu mới!</label>
          <Form.Item
            name="passwordrepeat"
            rules={[{ required: true, message: "Xác nhận lại mật khẩu mới!" }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Xác nhận lại mật khẩu mới!"
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title=""
        open={isTracuuDetail}
        width={3000}
        okText="Xem danh sách tra cứu"
        onOk={handleOkDetail}
        cancelText="Quay về"
        onCancel={handleCancelDetail}
      >
        <YeuCauDetail sochungtu={sochungtu} />
      </Modal>
    </div>
  );
};

export default TraCuuTinhTrangBaoHanh;
