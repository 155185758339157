import React, {Fragment } from "react"
import Header from "../../components/layout/header"
import Footer from "../../components/layout/footer"
import { withRouter } from "react-router-dom"

const Home = ({ children }) => {

  return (
    <Fragment>
        <div className='d-block'>
          <div className="hoplongtech" >
            <Header/>
            <div className="content" style={{minHeight:'60vh'}}>
            {children}
            </div>
            <Footer />  
          </div>
        </div>
    </Fragment>
  )
}

export default withRouter(Home)