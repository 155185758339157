import axios from "axios"

export const API_SERVER = 'https://salesdaily.hoplong.com/api'
export const API_SERVER_ERP = 'https://api.hoplong.com/api'
// export const API_SERVER_ERP = 'http://10.10.10.34/api/v1'
export const API_SERVER_GIGA = 'https://testserver.gigadigital.vn/v1'
export const API_SERVER_GHN = 'https://online-gateway.ghn.vn/shiip/public-api'


export const API_LOCAL1 = 'http://10.10.11.10:1234/api'



// export const API_LOCALFILE = 'http://10.10.11.10:2222/api'

// export const API_LOCALTEST = 'http://10.10.11.10:1111/api'

 export const API_LOCAL = 'https://gateway.hoplong.com/giga_daily/api'
 
 export const API_LOCALIMAGE = 'https://images.hoplong.com/api'



 export const API_TRA_CUU = 'https://api.hoplong.com/api'

const authHeader = () => {
  return {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
};

export const axiosIntance = axios.create({
  headers: authHeader()
})
