import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {

  return (
    <div className='footer'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
            <h5 className='mb-4'><b>CÔNG TY CỔ PHẦN CÔNG NGHỆ HỢP LONG</b></h5>
            <ul className='p-0 list-unstyled h-100'>
              <li><p className='mb-3'><i className='fa fa-map-marker-alt '></i><b>TTBH số 1: </b><Link to='/tram-bao-hanh/ha-noi'>Tòa D, Chung cư Báo Nhân dân, Xuân Phương, Nam Từ Liêm</Link></p></li>
              <li><p className='mb-3'><i className='fa fa-map-marker-alt'></i><b>TTBH số 2: </b><Link to='/tram-bao-hanh/ho-chi-minh'>55 Minh Phụng, Tân Thới Nhất, Quận 12, TP. Hồ Chí Minh</Link></p></li>
              <li><p ><i className='fa fa-map-marker-alt'></i><b>TTBH số 3: </b><Link to='/tram-bao-hanh/da-nang'>35 Chu Mạnh Trinh, Khuê Trung, Cẩm Lệ, Đà Nẵng</Link></p></li>
            </ul>
          </div>
          <div className='col-md-6'>
            <h5 className='mb-4'><b>LIÊN HỆ</b></h5>
            <ul className='p-0 list-unstyled h-100'>
              <li><p className='mb-3'><i className='fa fa-user me-2'></i><b>Liên hệ đối tác và đại lý: </b><span>0966.933.737</span></p></li>
              <li><p className='mb-3'><i className='fa fa-phone me-2'></i><b>Hotline bán lẻ và bảo hành: </b><span>1900.633.870</span></p></li>
              <li><p><i className='fa fa-envelope me-2'></i><b>Email: </b><span> info@gigadigital.vn </span></p></li>



            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer