import { combineReducers, createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import categories from './category/reducer'
import loading from './loading/reducer';

const rootReducer = combineReducers({
  categories, 
  loading
});

const store = createStore(rootReducer, applyMiddleware(thunk))
export default store