import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'reactstrap'
import IconGiga from '../../assets/image/giga-white.png'
import {  Modal,  Form, Input } from 'antd';
import {LogoutOutlined} from "@ant-design/icons"


import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { DoiMatKhau } from '../../redux/category/action';
const gigaWhite = 'https://img.gigadigital.vn/image/1663927866720-logoGiga-white.png'

const useViewPort = () => {

  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize)
  }, [])
  return { width }
}



const Header = () => {
  const [form] = Form.useForm();
  const [click, setClick] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const viewPort = useViewPort();
  const [maKH,setMaKH] = useState("");
  const isDesktop = viewPort.width >= 768
  const dispatch = useDispatch();
 

  return (
   
    <Fragment>
      <div className='p-0' id='top'>
        <Container fluid={true} className="search-page container">
          <Row className='align-items-top position-relative'>
            <Col xs="2" className='header-col-set w-10'>
              <Row>
                <div className="navbar-header d-flex align-items-center navbar-expand-md">
                  <Link to={'/homepage'} className="text-white logo-desktop" >
                    <img src={gigaWhite} className='position-relative w-74 h-100' style={{ width: 190, height: 80 }} alt='logo' />
                  </Link>
                  <Link to={'/homepage'} className="text-white logo-mobile" >
                    <img src={IconGiga} style={{ width: 40, padding: 5 }} />
                  </Link>
                  <button className="navbar-toggler" onClick={() => setClick(!click)} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false">
                    <i className="fas fa-bars text-white"></i>
                  </button>
                </div>

              </Row>
            </Col>

            {isDesktop && (
              <Col xs="10" className='header-col-set w-70 search-header' >
                <div className="list-navbar-items align-items-center justify-content-between w-100  navbar-collapse 222" id="navbarSupportedContent">
                  <ul className='list-unstyled d-flex align-items-center mb-0 justify-content-center' >
                    <li className='p-2'> <Link to={'/homepage'} className='text-white'>Trang chủ</Link></li>
                    <li className='p-2'> <Link to={'/kich-hoat-bao-hanh'} className='text-white'>Kích hoạt</Link></li>
                    <li className='p-2'> <Link to={'/thoi-han-bao-hanh'} className='text-white'>Tra cứu thời hạn</Link></li>
                    <li className='p-2'> <Link to={'/gui-yeu-cau'} className='text-white'>Tạo phiếu bảo hành</Link></li>
                    <li className='p-2'> <Link to={'/chinh-sach-bao-hanh'} className='text-white'>Chính sách bảo hành</Link></li>
             
                  </ul>
                </div>
              </Col>
            
            )}
            {
              click && (
                <div className="list-navbar-items align-items-center justify-content-between w-100  navbar-collapse 111" id="navbarSupportedContent">
                  <ul className='list-unstyled d-flex align-items-center mb-0 justify-content-center' >
                    <li className='p-2'> <Link to={'/homepage'} className='text-white'>Trang chủ</Link></li>
                    <li className='p-2'> <Link to={'/kich-hoat-bao-hanh'} className='text-white'>Kích hoạt</Link></li>
                    <li className='p-2'> <Link to={'/thoi-han-bao-hanh'} className='text-white'>Tra cứu thời hạn</Link></li>
                    <li className='p-2'> <Link to={'/gui-yeu-cau'} className='text-white'>Tạo phiếu bảo hành</Link></li>
                   
                    <li className='p-2'> <Link to={'/chinh-sach-bao-hanh'} className='text-white'>Chính sách bảo hành</Link></li>
                   
                  </ul>
                  

                </div>
              )
            }
            <Col className='w-10'>
              <ul className='list-unstyled list-data d-flex align-items-center mb-0'>
                <li className='mr-5' >  <a target={'_blank'} href='https://www.facebook.com/gigadigital.vn' className='text-primary'><i className='fa fa-facebook text-white'></i></a></li>
                <li className='mr-5' >  <Link className='text-primary'><i className='fa fa-envelope-o text-white'></i></Link></li>
              </ul>
            </Col>
            
          </Row>
        </Container>
      </div>
     
    </Fragment>
   
  )
}

export default Header