import { toast } from "react-toastify"
import { API_LOCAL, axiosIntance } from "./auth_header"

export const _LoginwithDaily = async (body) => {
    const request = await axiosIntance.post(`${API_LOCAL}/ApiGD_KichHoatBaoHanh/DangNhapKhachHangDaiLy`, body)
    if(request.data.statuscode == false){
        toast.error(request.data.Message)
      } else {
        toast.success("Đăng nhập thành công")
        return request.data;
      }
  }