import React, { Fragment } from 'react'
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom'
import { CSSTransition,TransitionGroup } from 'react-transition-group'
import Home from './screens/home'
import {routes} from './routes/index'
import 'bootstrap/dist/css/bootstrap.css'
import './assets/scss/app.scss'
import "react-toastify/dist/ReactToastify.css"
import { ToastContainer } from 'react-toastify'

const App = () => {

  return (
    <Fragment >
      <BrowserRouter basename={`/`}>
          <Home>
          <Route exact path={'/'} render={() => {
              return (<Redirect to='/homepage'/>)
          }} /> 
          <TransitionGroup>
            <CSSTransition 
              timeout={0}
              unmountOnExit>
              <Switch>
              {routes.map(({ path, Component }) => (
                <Route key={path} exact path={`${path}`}>
                  {() => (
                    <Component />
                  )}
                </Route>
              ))}
              </Switch>
            </CSSTransition> 
          </TransitionGroup> 
          </Home>
    </BrowserRouter>
    <ToastContainer />
    </Fragment>
  )
}

export default App