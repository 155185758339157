import React from 'react'
import { scroller, Link as LinkTo, Element } from 'react-scroll'
import linkPDF from '../../assets/pdf/chinh-sach-giga.pdf'
import pdfChinhSach from '../../assets/pdf/chinh-sach-dich-vu-giga.pdf'


const chinhSachBaoHanh = () => {
  return (
    <div className="container chinh-sach">
      <div className="chinh-sach-bao-hanh-chung">
        <h5 className="fw-bold text-center text-primary">CHÍNH SÁCH BẢO HÀNH CHUNG</h5>
        <div className="content">
          <ul className="list-unstyled">
            <li className="mb-2">
              <p>Chúng tôi xin chân thành cảm ơn Quý khách hàng đã tin tưởng lựa chọn và mua sắm các sản phẩm tại GIGA.vn.</p>
            </li>
            <li className="mb-2">
              <p>GIGA.vn cam kết tất cả các sản phẩm bán ra tại hệ thống của chúng tôi đều là sản phẩm chính hãng, được bảo hành đúng theo quy định của Hãng sản xuất cũng như chính sách bảo hành riêng của chúng tôi.</p>
            </li>
            <li className="mb-2">
              <p>Dưới đây là chính sách bảo hành của GIGA.vn áp dụng chung cho tất cả các sản phẩm (<i>đối với các sản phẩm thương hiệu <strong>ECOVACS</strong>, chúng tôi có thêm một số lưu ý riêng so với Chính sách chung này, Quý khách vui lòng xem chi tiết <LinkTo className='text-primary text-decoration-underline cursor-pointer' to="section1" spy={true} smooth={true} duration={500}>tại đây</LinkTo> </i>). GIGA.vn luôn nỗ lực nhằm mang lại những trải nghiệm mua sắm hài lòng nhất đến Quý khách.</p>
            </li>
          </ul>
          <h6 className='mt-3 fw-bold'>1. NGUYÊN TẮC BẢO HÀNH</h6>
          <ul className="list-unstyled">
            <li className="mb-2"><p>- Chính sách bảo hành của GIGA.vn chỉ áp dụng cho các sản phẩm do GIGA.vn phân phối hoặc được ủy quyền tiếp nhận bảo hành từ hãng sản xuất.</p></li>
            <li className="mb-2"><p>- Chương trình bảo hành bắt đầu có hiệu lực từ thời điểm GIGA.vn xuất hóa đơn/Phiếu mua hàng/Phiếu xuất kho cho Quý khách. Thời hạn bảo hành được quy định trên từng đường link sản phẩm cụ thể được đăng tải trên website Giga.vn hoặc căn cứ thời hạn bảo hành trên chứng từ mua hàng <i>(với các sản phẩm được lựa chọn thời hạn bảo hành)</i>.</p></li>
            <li className="mb-2"><p>- Mỗi sản phẩm sẽ có chính sách, thời hạn bảo hành khác nhau tùy theo đặc thù của sản phẩm và quy định của Hãng sản xuất.</p> </li>
            <li className="mb-2"><p>- Để tìm hiểu thông tin chi tiết về chính sách bảo hành cho sản phẩm cụ thể, xin liên hệ bộ phận Tiếp nhận bảo hành của GIGA.vn theo hotline: 1900-633-870.</p></li>
            <li className="mb-2"><p>- Đối với các sản phẩm có sử dụng pin hoặc các phụ kiện điện (như điều khiển từ xa,...), thời hạn bảo hành của pin và các phụ kiện điện này sẽ được tính bằng nửa thời gian bảo hành của sản phẩm chính.</p></li>
            <li className="mb-2"><p>- Các linh kiện thay thế, sửa chữa hoặc sản phẩm đổi trả sẽ được bảo hành 3 tháng tính từ ngày khách hàng nhận lại sản phẩm, hoặc theo thời hạn bảo hành còn lại của sản phẩm, tùy theo thời hạn nào dài hơn</p></li>
          </ul>
          {/* <label className='d-block'>(Quý khách vui lòng đọc kĩ điều kiện bảo hành và đổi trả trong 30 ngày bên dưới)</label> */}
          <h6 className='mt-3 fw-bold'>2. ĐIỀU KIỆN BẢO HÀNH</h6>
          <ul className="list-unstyled">
            <li className="mb-2"><p>- Sản phẩm đủ điều kiện bảo hành theo công bố chính thức của hãng sản xuất. </p></li>
            <li className="mb-2"><p>- Sản phẩm còn vỏ sản phẩm cùng phụ kiện đi kèm (ví dụ: điều khiển, dây sạc, củ sạc, thẻ, chân đế, ốc vít, phụ kiện lắp đặt…)</p></li>
            <li className="mb-2"><p>- Số Seri, imei định danh sản phẩm phải rõ nét, trùng khớp với chứng từ mua bán hàng hóa.</p></li>
          </ul>
          <h6 className='mt-3 fw-bold'>3. CÁC TRƯỜNG HỢP KHÔNG ĐƯỢC BẢO HÀNH MIỄN PHÍ</h6>
          <i><strong>Những trường hợp dưới đây không nằm trong chính sách bảo hành miễn phí của Nhà sản xuất:</strong></i>
          <ul className="list-unstyled mt-3">
            <li className="mb-2">
              <p>- Sản phẩm có lỗi do người dùng lắp đặt, sử dụng, bảo quản sản phẩm không đúng hướng dẫn, khuyến cáo của Nhà sản xuất.</p>
            </li>
            <li className="mb-2">
              <p>- Người dùng can thiệp cải tạo hình thức, công năng máy, up ROM, chạy phần mềm không chính hãng, root máy, các hình thức can thiệp phần cứng và phần mềm khác...</p>
            </li>
            <li className="mb-2">
              <p>- Tài khoản và password của quý khách đăng nhập không hợp lệ (bao gồm cả trường hợp máy bật không lên).</p>
            </li>
            <li className="mb-2">
              <p>- Sản phẩm có dấu hiệu hư hỏng do bị cạy mở, mất, rách tem bảo hành, bị tác động của ngoại lực, bị động vật xâm nhập, sản phẩm bị tác động bởi hỏa hoạn, thiên tai, hóa chất, chất lỏng...</p>
            </li>
            <li className="mb-2">
              <p>- Lỗi do hao mòn tự nhiên.</p>
            </li>
            <li className="mb-2">
              <p>- Các vết trầy xước, cấn móp, tróc sơn không thuộc phạm vi bảo hành.</p>
            </li>

          </ul>
          <i><strong>Với các sản phẩm không thuộc phạm vi bảo hành miễn phí, GIGA.vn cung cấp dịch vụ chăm sóc, sửa chữa sản phẩm theo nhu cầu có tính phí (Quý khách tham khảo biểu phí dịch vụ <a href={pdfChinhSach} target="_blank" className='text-primary text-decoration-underline'>tại đây</a>).</strong></i>
          <h6 className='mt-3 fw-bold'>4. QUY TRÌNH BẢO HÀNH</h6>
          <ul className="list-unstyled">
            <li className="mb-2"><p>- <strong>Thời gian thực hiện hiện việc bảo hành:</strong> thời gian tiêu chuẩn là 15 ngày làm việc tính từ ngày chúng tôi nhận được sản phẩm (không bao gồm ngày nghỉ, ngày lễ). <i>Thời gian bảo hành có thể sẽ được rút ngắn hoặc kéo dài phụ thuộc vào tình trạng sản phẩm và được chúng tôi thông báo cụ thể cho Quý khách.</i></p></li>
            <li className="mb-2"><p>- <strong>Địa điểm tiếp nhận sản phẩm cần bảo hành:</strong> Quý khách có thể trực tiếp mang sản phẩm tới tất cả các showroom của GIGA.vn trên toàn quốc, hoặc gửi chuyển phát nhanh tới trung tâm bảo hành gần nhất của chúng tôi theo hướng dẫn của nhân viên tư vấn (Hotline 1900-633-870). </p></li>
            <li className="mb-2"><p>- <strong>Phí vận chuyển sản phẩm bảo hành:</strong> Quý khách vui lòng thanh toán phí vận chuyển khi gửi hàng bảo hành, GIGA.vn sẽ thanh toán phí vận chuyển khi hoàn trả lại sản phẩm tới Quý khách. </p></li>
          </ul>
          <p><i>Lưu ý: Quý khách vui lòng tự sao lưu tất cả các dữ liệu cá nhân trước khi mang sản phẩm tới trung tâm bảo hành, trong quá trình bảo hành GIGA.vn không chịu trách nhiệm về mọi mất mát dữ liệu cá nhân và các ứng dụng cài đặt thêm được lưu trữ trong máy.</i></p>
          <p className="my-3"><strong>- Quy trình thực hiện bảo hành sản phẩm:</strong></p>
          <div class="flowchart">
            <div class="item">
              <div class="content-detail">Khách hàng gửi thông tin yêu cầu bảo hành tới GIGA.VN</div>
              <div class="arrow arrow-bar is-bottom"></div>
            </div>
            <div class="item">
              <div class="content-detail">GIGA tiếp nhận sản phẩm gửi đến từ khách hàng</div>
              <div class="arrow arrow-bar is-bottom"></div>
            </div>
            <div class="item">
              <div class="content-detail">GIGA thực hiện đánh giá, đưa ra phương án sửa chữa bảo hành (Thông báo, xác nhận phí dịch vụ và sửa chữa nếu có từ khách hàng)</div>
              <div class="arrow arrow-bar is-bottom"></div>
            </div>
            <div class="item">
              <div class="content-detail">GIGA thông báo sản phẩm đã hoàn thành sửa chữa, xác nhận thông tin địa chỉ và thực hiện hoàn trả sản phẩm</div>
              <div class="arrow arrow-bar is-bottom"></div>
            </div>
            <div class="item">
              <div class="content-detail">Hoàn tất dịch vụ bảo hành khi khách hàng đã nhận lại sản phẩm.</div>
            </div>
          </div>
          {/* <h6 className='mt-3 fw-bold'> QÚY KHÁCH LƯU Ý!</h6>
          <ul>
            <li>
              Quý khách không nên sử dụng sạc không theo đúng quy chuẩn của nhà sản xuất (sẽ gây lỗi nguồn sản phẩm)
            </li>
            <li>
              Quý khách vui lòng tự sao lưu tất cả các dữ liệu cá nhân trước khi mang sản phẩm tới trung tâm bảo hành, trong quá trình bảo hành Giga Digital không chịu trách nhiệm về mọi mất mát dữ liệu cá nhân và các ứng dụng cài đặt thêm được lưu trữ trong máy.
            </li>
            <li>Việc sửa chữa và linh kiện thay thế hoặc sản phẩm đổi sẽ được bảo hành 3 tháng tính từ ngày khách hàng nhận lại sản phẩm, hoặc theo thời hạn bảo hành còn lại của sản phẩm, tùy theo thời hạn nào dài hơn.
            </li>
          </ul> */}
        </div>
      </div>
      <div className="chinh-sach-bao-hanh-giga mt-5">
      <Element name="section1" classID="section1">
        <h5 className="fw-bold text-center text-primary">CHÍNH SÁCH BẢO HÀNH CỦA GIGA.VN ĐỐI VỚI SẢN PHẨM ECOVACS</h5>
      </Element>
        <div className="content">
          <p className="mt-3"><i>Chính sách bảo hành này được áp dụng riêng cho các sản phẩm mang thương hiệu ECOVACS. Các nội dung không được sửa đổi, bổ sung hay quy định chi tiết bởi chính sách riêng này sẽ tuân thủ theo quy định bảo hành chung của GIGA.vn</i></p>
          <h6 className='mt-3 fw-bold'>1. SẢN PHẨM ĐƯỢC HƯỞNG CHÍNH SÁCH BẢO HÀNH MIỄN PHÍ</h6>
          <ul className="list-unstyled">
            <li className="mb-2"><p>- Các sản phẩm mang nhãn hiệu Ecovacs do GIGA.vn cung cấp.</p></li>
            <li className="mb-2"><p>- Bảo hành do lỗi vật liệu và gia công trên sản phẩm trong điều kiện sử dụng bình thường. </p></li>
            <li className="mb-2"><p>- Chỉ bảo hành cho sản phẩm chính.</p> </li>
            <li className="mb-2"><p>- Chỉ bảo hành khi sản phẩm còn tem serial.</p></li>
          </ul>

          <h6 className='mt-3 fw-bold'>2. CÁC TRƯỜNG HỢP KHÔNG ÁP DỤNG CHÍNH SÁCH BẢO HÀNH MIỄN PHÍ</h6>
          <ul className="list-unstyled">
            <li className="mb-2"><p>- KHÔNG áp dụng bảo hành đối với cảm ứng và màn hình của máy.</p></li>
            <li className="mb-2"><p>- KHÔNG bảo hành các thành phần dễ ăn mòn như: Pin điều khiển từ xa, giẻ lau nhà, cây lau nhà, bộ lọc, chổi chính, chổi phụ, dung dịch làm sạch, cốc và chổi cao su, bánh xe…</p></li>
            <li className="mb-2"><p>- KHÔNG áp dụng cho phụ kiện đi kèm sản phẩm chính.</p> </li>
          </ul>
          <h6 className='mt-3 fw-bold'>3. CAM KẾT BẢO HÀNH</h6>
          <ul className="list-unstyled">
            <li className="mb-2">
              <p>- GIGA.vn cam kết:</p>
              <ul style={{ listStyleType: 'none' }} className="mt-2">
                <li className="mb-2">
                  <p>+ Hoàn thành quy trình bảo hành trong vòng tối đa 15 ngày (tính từ ngày nhận máy ở trạng thái lỗi đến ngày tiến hành xong việc bảo hành).</p>
                </li>
                <li className="mb-2">
                  <p>+ Sản phẩm không phải bảo hành lại trong vòng 30 ngày kể từ ngày khách hàng nhận được sản phẩm đã hoàn thành quy trình bảo hành.</p>
                </li>
              </ul>
            </li>
            <li className="mb-2"><p>- Nếu GIGA.vn vi phạm cam kết nêu trên, chúng tôi sẽ gửi tặng Quý khách gói dịch vụ Premium Care miễn phí như sau:</p></li>

          </ul>
          <table className="border-1 my-3">
            <thead>
              <tr>
                <th className="text-center text-primary">Sản phẩm có giá dưới 10 triệu đồng.</th>
                <th className="text-center text-primary">Sản phẩm có giá trên 10 triệu đồng.</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">Gói Premium Care thời hạn sử dụng trong 1 tháng.</td>
                <td className="text-center">Gói Premium Care thời hạn sử dụng trong 2 tháng.</td>
              </tr>

            </tbody>
          </table>
          <p>Chi tiết về gói Premium Care Quý khách vui lòng xem <a href={linkPDF} target="_blank" className='text-primary text-decoration-underline'>tại đây</a></p>
          <h6 className='mt-3 fw-bold'>4. KHUYẾN CÁO </h6>
          <ul className="list-unstyled">
            <li className="mb-2"><p>- Quý khách không nên sử dụng sạc không theo đúng quy chuẩn của nhà sản xuất (sẽ gây lỗi nguồn sản phẩm), không sử dụng các loại nước lau sàn/tinh dầu không phải do Ecovacs sản xuất (tránh việc chất lỏng không tương thích làm giảm tuổi thọ hoặc làm hỏng robot).</p></li>
            <li className="mb-2"><p>- Quý khách vui lòng tự sao lưu tất cả các dữ liệu cá nhân trước khi mang sản phẩm tới trung tâm bảo hành, trong quá trình bảo hành GIGA.vn không chịu trách nhiệm về mọi mất mát dữ liệu cá nhân và các ứng dụng cài đặt thêm được lưu trữ trong máy.</p></li>
            <li className="mb-2"><p>- Các linh kiện thay thế, sửa chữa hoặc sản phẩm đổi trả sẽ được bảo hành 3 tháng tính từ ngày khách hàng nhận lại sản phẩm, hoặc theo thời hạn bảo hành còn lại của sản phẩm, tùy theo thời hạn nào dài hơn.</p> </li>
          </ul>

        </div>
      </div>
      {/* <div className="chinh-sach-huy-doi-tra mt-5">
        <h5 className="fw-bold text-center text-primary">CHÍNH SÁCH HỦY, ĐỔI TRẢ, HOÀN TIỀN CHUNG</h5>
        <div className="content">
          <p>Dưới đây là chính sách hủy đơn hàng, đổi trả hàng, hoàn tiền của GIGA.vn áp dụng chung cho tất cả các sản phẩm <i>(đối với các sản phẩm thương hiệu <strong>ECOVACS</strong>,  chúng tôi có thêm một số lưu ý riêng so với Chính sách chung này, Quý khách vui lòng xem chi tiết <Link className='text-primary text-decoration-underline'>tại đây</Link>)</i>.</p>
          <h6 className='mt-3 fw-bold'>1. CHÍNH SÁCH HỦY ĐƠN HÀNG</h6>
          <p className="mb-2">Quý khách được thực hiện hủy đơn hàng trong vòng 24h kể từ thời điểm Quý khách thanh toán thành công. Quý khách vui lòng liên hệ với Chúng tôi theo số điện thoại 1900.633.870 hoặc địa chỉ email info@giga.vn để được hỗ trợ hủy đơn hàng.</p>
          <ul className="list-unstyled">
            <li className="mb-2"><p>- Nếu quý khách hủy đơn hàng trước khi hàng được vận chuyển, thông thường là trong vòng 1 giờ kể từ lúc nhân viên bán hàng liên hệ xác nhận đặt hàng, Chúng tôi sẽ hoàn trả 100% tiền cho những quý khách đã thanh toán.</p></li>
            <li className="mb-2"><p>- Nếu quý khách hủy đơn hàng sau khi hàng đã được vận chuyển, Chúng tôi sẽ giải quyết hoàn tiền cho quý khách sau khi đã trừ các chi phí phát sinh của đơn hàng như: phí vận chuyển, phí thanh toán, phí đóng gói,….</p></li>
            <li className="mb-2"><p>- Để biết tình trạng hiện tại của đơn hàng, quý khách vui lòng xem trong mục Tra cứu đơn hàng trên website gigadigital.vn hoặc liên hệ theo số điện thoại 1900.633.870 để được hỗ trợ.</p> </li>
            <li className="mb-2"><p>- Quá thời gian qui định trên, mọi yêu cầu hủy đơn hàng của Quý khách sẽ không được chấp nhận.</p></li>
          </ul>

          <h6 className='mt-3 fw-bold'>2. CHÍNH SÁCH ĐỔI TRẢ</h6>
          <p className="mb-2"><strong>A. Nếu sản phẩm có lỗi phần cứng do nhà sản xuất, đủ điều kiện bảo hành.</strong></p>
          <div className="table-responsive">
            <table className="border-1 mb-3">
              <thead>
                <tr>
                  <th style={{ minWidth: 80 }}></th>
                  <th className="text-center text-primary" style={{ minWidth: 250 }}>Trong vòng 15 ngày đầu</th>
                  <th className="text-center text-primary" style={{ minWidth: 250 }}>Từ ngày thứ 16 tới hết thời hạn bảo hành của sản phẩm</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center">Phí đổi trả</td>
                  <td className="middle">
                    <ul className="list-unstyled">
                      <li className="mb-2">
                        <p>Trường hợp hư hỏng phụ kiện đi kèm (pin, dây cáp, bộ sạc, điều khiển từ xa, chổi, giẻ lau, bánh xe,…) do lỗi nhà sản xuất, Quý khách sẽ được đổi mới phụ kiện tương ứng, không đổi mới nguyên bộ sản phẩm.</p>
                      </li>
                      <li className="mb-2">
                        <p><i>Trường hợp GIGA.vn không còn sẵn hàng sản phẩm cùng loại để thực hiện đổi cho Quý khách, Quý khách có thể lựa chọn:</i></p>
                      </li>
                      <li className="mb-2">
                        <p><i>+ Đổi sang sản phẩm khác thanh toán thêm phần tiền chênh lệch, sản phẩm đổi được áp dụng mức giá ưu đãi so với giá niêm yết dành riêng cho từng trường hợp.</i></p>
                      </li>
                      <li>
                        <p><i>+ Hoặc áp dụng chính sách trả hàng hoàn tiền;</i></p>
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul className="list-unstyled">
                      <li className="mb-2">
                        <p>Chúng tôi thực hiện bảo hành bằng cách sửa chữa thay thế linh kiện. Trường hợp không thể sửa chữa sẽ đổi mới miễn phí sang sản phẩm tương đương.</p>
                      </li>
                      <li className="mb-2">
                        <p><i>Trường hợp chúng tôi không còn/không có ngay sản phẩm cùng chủng loại để đổi cho Quý khách, Giga sẽ đổi mới sang sản phẩm tương đương về công năng, thiết kế, giá trị (có thể là sản phẩm trưng bày/không nguyên seal).</i></p>
                      </li>
                      <li className="mb-2">
                        <p><i>Nếu Quý khách có nhu cầu đổi sang sản phẩm khác có giá trị cao hơn sản phẩm ban đầu, Quý khách vui lòng thanh toán thêm giá trị chênh lệch (không áp dụng đổi trả sản phẩm thấp hơn giá trị sản phẩm ban đầu).</i></p>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className="text-center">Phí vận chuyển</td>
                  <td> GIGA.vn hỗ trợ phí vận chuyển 2 chiều.</td>
                  <td>Quý khách vui lòng thanh toán phí vận chuyển khi gửi hàng bảo hành, GIGA.vn sẽ thanh toán phí vận chuyển khi hoàn trả lại sản phẩm tới Quý khách.</td>
                </tr>
                <tr>
                  <td className="text-center">Thời gian xử lý đổi trả </td>
                  <td colSpan={2}> Trong vòng 7 ngày làm việc kể từ khi chúng tôi nhận được sản phẩm hoàn trả và kiểm tra, xác nhận đủ điều kiện đổi trả.</td>
                </tr>
              </tbody>
            </table>
          </div>

          <p className="mb-2"><strong>B. Nếu sản phẩm không có lỗi phần cứng do nhà sản xuất:</strong></p>
          <p className="my-3"><strong>Điều kiện đổi trả:</strong></p>
          <ul className="list-unstyled">
            <li className="mb-2"><p>- Sản phẩm đổi trả phải giữ nguyên 100% hình dạng ban đầu, không bị xước, cấn, móp vỡ…, không bị cải tạo hoặc can thiệp thay đổi công năng máy mà không phải do GIGA.vn chỉ định.</p></li>
            <li className="mb-2">
              <p>- Sản phẩm đổi trả phải phải có đầy đủ hộp và phụ kiện đi kèm:</p>
              <ul style={{ listStyleType: 'none' }} className="mt-2">
                <li className="mb-2">
                  <p>+ Trường hợp mất hộp thu phí 5% giá trị hóa đơn mua hàng; </p>
                </li>
                <li className="mb-2">
                  <p>+ Trường hợp mất phụ kiện thu phí tối đa 5% giá trị hóa đơn mua hàng.</p>
                </li>
              </ul>
            </li>
            <li className="mb-2"><p>- Sản phẩm chính phải được hoàn trả kèm toàn bộ hàng khuyến mãi ở trạng thái nguyên vẹn chưa qua sử dụng. Nếu hàng khuyến mãi không đủ điều kiện hoàn trả, GIGA.vn sẽ thu phí hàng khuyến mãi theo mức giá đã được công bố tại thời điểm bán sản phẩm chính.</p></li>
            <li className="mb-2"><p>- Sản phẩm chỉ được dùng cho mục đích sử dụng cá nhân, KHÔNG áp dụng đổi trả sản phẩm được sử dụng cho mục đích thương mại.</p></li>
            <li className="mb-2"><p>- Chỉ được đổi sang sản phẩm giá trị CAO HƠN hoặc BẰNG sản phẩm đã mua.</p></li>
            <li className="mb-2"><p>- Chỉ được áp dụng 1 lần duy nhất với 1 sản phẩm.</p></li>
          </ul>
          <table className="border-1 my-3 w-100">
            <thead>
              <tr>
                <th style={{ minWidth: 80 }}></th>
                <th className="text-center text-primary">Trong vòng 15 ngày đầu</th>
                <th className="text-center text-primary">Từ ngày thứ 16</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-left">Phí đổi trả</td>
                <td className="middle">
                  <ul className="list-unstyled mb-0">
                    <li className="mb-2">
                      <p>- Phí 20% giá mua:  nếu sản phẩm còn mới nguyên vẹn chưa qua sử dụng và đầy đủ hộp, phụ kiện.</p>
                    </li>
                    <li className="">
                      <p>- Phí 30% giá mua: nếu sản phẩm đã qua sử dụng, còn đầy đủ hộp, phụ kiện.</p>
                    </li>
                  </ul>
                </td>
                <td rowSpan={3} className="text-center">Không áp dụng chính sách đổi trả.</td>
              </tr>
              <tr>
                <td className="text-left">Phí vận chuyển</td>
                <td>Quý khách vui lòng thanh toán phí vận chuyển 2 chiều (khi gửi trả sản phẩm ban đầu và khi nhận lại sản phẩm được đổi mới).</td>

              </tr>
              <tr>
                <td className="text-left">Thời gian xử lý đổi trả </td>
                <td> Trong vòng 7 ngày làm việc kể từ khi chúng tôi nhận được sản phẩm hoàn trả và kiểm tra, xác nhận đủ điều kiện đổi trả.</td>
              </tr>
            </tbody>
          </table>
          <p className="mb-2"><strong>C. Sản phẩm lỗi do người sử dụng.</strong></p>
          <ul className="list-unstyled">
            <li className="mb-2"><p>Không áp dụng chính sách đổi trả. Trường hợp này, GIGA.vn hỗ trợ Quý khách chuyển sản phẩm đến Trung tâm bảo hành của hãng hoặc Trung tâm bảo hành của GIGA.vn, khách hàng vui lòng thanh toán phí sửa chữa, thay thế các linh kiện, chi phí vận chuyển, chi phí khác liên quan nếu có.</p></li>
          </ul>
          <h6 className='mt-3 fw-bold'>3. CHÍNH SÁCH HOÀN TIỀN</h6>
          <p className="mb-2">Nếu sản phẩm bị lỗi phần cứng do nhà sản xuất nhưng Quý khách không có nhu cầu đổi sang sản phẩm khác theo chính sách bảo hành, GIGA.vn sẽ áp dụng chính sách trả hàng hoàn tiền như sau:</p>
          <ul className="list-unstyled">
            <p className="mb-2"><strong>Điều kiện trả hàng hoàn tiền:</strong></p>
            <li className="mb-2"><p>- Chính sách trả hàng hoàn tiền chỉ áp dụng cho sản phẩm có lỗi phần cứng do nhà sản xuất, đủ điều kiện bảo hành.</p></li>
            <li className="mb-2"><p>- Sản phẩm hoàn trả phải giữ nguyên 100% hình dạng ban đầu, không bị xước, cấn, móp vỡ…, không bị cải tạo hoặc can thiệp thay đổi công năng máy mà không phải do GIGA.vn chỉ định.</p></li>
            <li className="mb-2">
              <p>- Sản phẩm hoàn trả phải phải có đầy đủ hộp và phụ kiện đi kèm:</p>
              <ul style={{ listStyleType: 'none' }} className="mt-2">
                <li>+ Trường hợp mất hộp thu phí 5% giá trị hóa đơn mua hàng; </li>
                <li>+ Trường hợp mất phụ kiện thu phí tối đa 5% giá trị hóa đơn mua hàng. </li>
              </ul>
            </li>
            <li className="mb-2"><p>- Sản phẩm chính phải được hoàn trả kèm toàn bộ hàng khuyến mãi ở trạng thái nguyên vẹn chưa qua sử dụng. Nếu hàng khuyến mãi không đủ điều kiện hoàn trả, GIGA.vn sẽ thu phí hàng khuyến mãi theo mức giá đã được công bố tại thời điểm bán sản phẩm chính.</p></li>
            <li className="mb-2"><p>- Sản phẩm chỉ được dùng cho mục đích sử dụng cá nhân, KHÔNG áp dụng hoàn trả sản phẩm được sử dụng cho mục đích thương mại</p></li>
          </ul>
          <table className="border-1 mb-3 w-100">
            <thead>
              <tr>
                <th className="text-center color-table-header">Trong 15 ngày đầu tiên kể từ ngày mua hàng</th>
                <th className="text-center color-table-header">Từ ngày thứ 15 trở đi kể từ ngày mua hàng</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">Hoàn đến 100% giá trị hóa đơn</td>
                <td className="text-center">Không áp dụng chính sách hoàn tiền</td>
              </tr>
            </tbody>
          </table>
          <p className="mt-3"><i>Lưu ý: Tùy theo tình trạng của máy Quý khách sẽ được thông báo mức hoàn tiền cụ thể sau khi trừ đi phí mất phụ kiện, vỏ hộp, quà tặng đi kèm (nếu có).</i></p>
        </div>
      </div>

      <div className="chinh-sach-tra-hoan-tien mt-5">
        <h5 className="fw-bold text-center text-primary">CHÍNH SÁCH ĐỔI TRẢ HÀNG – HOÀN TIỀN CỦA GIGA.VN ĐỐI VỚI SẢN PHẨM ECOVACS</h5>
        <div className="content">
          <p><i>Chính sách trả hàng, hoàn tiền này được áp dụng cho sản phẩm mang thương hiệu ECOVACS, các nội dung không được sửa đổi, bổ sung hay quy định chi tiết bởi chính sách riêng này sẽ tuân thủ theo quy định hủy đơn hàng, trả hàng chung của GIGA.vn.</i></p>
          <h6 className='mt-3 fw-bold'>1. ĐIỀU KIỆN ĐỔI TRẢ HÀNG – HOÀN TIỀN</h6>
          <ul className="list-unstyled">
            <li className="mb-2">
              <p>- Trong thời gian 30 ngày kế từ ngày mua sản phẩm mà: sản phẩm bị gặp lỗi phần cứng hoặc sản phẩm không gặp lỗi nhưng Quý khách có nhu cầu đổi sang mã sản phẩm khác do nhu cầu cá nhân đồng thời đáp ứng đủ điều kiện bảo hành miễn phí với sản phẩm Ecovacs như nêu trên. </p>
            </li>
            <li className="mb-2">
              <p>- Sản phẩm đổi trả phải giữ nguyên 100% hình dạng ban đầu, không bị xước, cấn, móp vỡ…, không bị cải tạo hoặc can thiệp thay đổi công năng máy mà không phải do Ecovacs chỉ định.</p>
            </li>
            <li className="mb-2">
              <p>- Sản phẩm đổi trả phải phải có đầy đủ hộp và phụ kiện đi kèm:</p>
              <ul style={{ listStyleType: 'none' }} className="mt-2">
                <li><p>+ Trường hợp mất hộp thu phí 5% giá trị hóa đơn mua hàng; </p></li>
                <li><p>+ 	Trường hợp mất phụ kiện thu phí tối đa 5% giá trị hóa đơn mua hàng. </p></li>
              </ul>
            </li>
            <li className="mb-2">
              <p>- Sản phẩm chính phải được hoàn trả kèm toàn bộ hàng khuyến mãi ở trạng thái nguyên vẹn chưa qua sử dụng. Nếu hàng khuyến mãi không đủ điều kiện hoàn trả, Giga sẽ thu phí hàng khuyến mãi theo mức giá đã được công bố tại thời điểm bán sản phẩm chính.</p>
            </li>
            <li className="mb-2">
              <p>- Sản phẩm chỉ được dùng cho mục đích sử dụng cá nhân, KHÔNG áp dụng đổi trả sản phẩm được sử dụng sản phẩm cho mục đích thương mại.</p>
            </li>
            <li className="mb-2">
              <p>- Chỉ được đổi sang sản phẩm giá trị CAO HƠN hoặc BẰNG sản phẩm đã mua.</p>
            </li>
            <li className="mb-2">
              <p>- Chỉ được áp dụng 1 lần duy nhất với 1 sản phẩm.</p>
            </li>
          </ul>
          <h6 className='mt-3 fw-bold'>2. CHÍNH SÁCH ĐỔI TRẢ HÀNG </h6>
          <div className="table-responsive">
          <table className="border-1 my-3">
            <thead>
              <tr>
                <th className="text-center color-table-header" style={{ minWidth: 150 }}>Thời gian/Điều kiện áp dụng</th>
                <th className="text-center color-table-header" style={{ minWidth: 250 }}>Từ ngày 1 đến ngày thứ 15 kể từ ngày mua hàng</th>
                <th className="text-center color-table-header" style={{ minWidth: 250 }}>Từ ngày thứ 16 đến ngày thứ 30 kể từ ngày mua hàng</th>
                <th className="text-center color-table-header" style={{ minWidth: 250 }}>Từ ngày thứ 31 trở đi kể từ ngày mua hàng</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan={2}>Nếu sản phẩm gặp lỗi phần cứng do nhà sản xuất</td>
                <td> Miễn phí </td>
                <td>Phí 20% giá trị hóa đơn mua sản phẩm + Bù tiền chênh lệch nếu đổi sản phẩm giá trị cao hơn.</td>
                <td rowSpan={4} className="text-center">Chỉ áp dụng chính sách bảo hành, không áp dụng chính sách đổi trả hàng.</td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <p className="mb-2">Trường hợp GIGA.vn không còn sẵn hàng sản phẩm cùng loại để thực hiện đổi cho Quý khách, Quý khách có thể lựa chọn: </p>
                  <ul className="list-unstyled">
                    <li className="mb-2">
                      <p>+ Áp dụng chính sách trả hàng hoàn tiền; </p>
                    </li>
                    <li className="mb-2">
                      <p>+ Nhận gói Premium Care miễn phí (thông tin chi tiết gói <Link className='text-primary text-decoration-underline'>tại đây</Link> );</p>
                    </li>
                    <li className="mb-2">
                      <p>+ Hoặc đổi sang sản phẩm khác thanh toán thêm phần tiền chênh lệch, sản phẩm đổi được áp dụng mức giá ưu đãi so với giá niêm yết dành riêng cho từng trường hợp.</p>
                    </li>
                    <li className="">
                      <p>+ Hoặc áp dụng chính sách trả hàng hoàn tiền.</p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td className="text-left">Nếu sản phẩm không gặp lỗi phần cứng do nhà sản xuất nhưng khách hàng có nhu cầu đổi sang mẫu sản phẩm khác</td>
                <td colSpan={2}> Phí 50% giá trị hóa đơn mua sản phẩm + Bù tiền chênh lệch nếu đổi sản phẩm giá trị cao hơn.</td>
              </tr>
              <tr>
                <td className="text-left">Áp dụng chung </td>
                <td colSpan={2}> Trường hợp Khách hàng muốn đổi hàng full box (nguyên thùng, nguyên hộp) thì Khách hàng sẽ trả thêm phí lấy full box tương đương 20% giá trị hóa đơn.</td>
              </tr>

            </tbody>
          </table>
          </div>
       
          <h6 className='mt-3 fw-bold'>3. CHÍNH SÁCH TRẢ HÀNG HOÀN TIỀN</h6>
          <p className="mb-2">Nếu sản phẩm bị lỗi phần cứng do nhà sản xuất nhưng Quý khách không có nhu cầu đổi sang sản phẩm khác, GIGA.vn sẽ áp dụng chính sách trả hàng hoàn tiền như sau:</p>
          <table className="border-1 my-3 w-100">
            <thead>
              <tr>
                <th className="text-center color-table-header">Trong 30 ngày đầu tiên kể từ ngày mua hàng</th>
                <th className="text-center color-table-header">Từ ngày thứ 31 đến 60 kể từ ngày mua hàng</th>
                <th className="text-center color-table-header">Từ ngày thứ 61 trở đi kể từ ngày mua hàng</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">Hoàn đến 65% giá trị hóa đơn</td>
                <td className="text-center">Hoàn đến 50% giá trị hóa đơn</td>
                <td className="text-center" rowSpan={2}>Không áp dụng chính sách hoàn tiền. </td>
              </tr>
              <tr>
                <td colSpan={2} className="text-center"><i>Tùy theo tình trạng của máy Quý khách sẽ được thông báo mức hoàn tiền cụ thể sau khi trừ đi phí mất phụ kiện, vỏ hộp, quà tặng đi kèm (nếu có).</i></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}
    </div>
  )
}

export default chinhSachBaoHanh       