import { findIndex } from "lodash"
import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import ReactHtmlParser from 'react-html-parser'

export const addressStore = [
  {
    _id: 1,
    city: 'Trung tâm bảo hành số 1',
    slug: 'ha-noi',
    store: [
      {
        address: 'Tòa D, Chung cư Báo Nhân dân, Xuân Phương, Nam Từ Liêm',
        link: '/chi-nhanh/ha-noi',
        map: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.045591750221!2d105.74971107599858!3d21.03086168770508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31345490d9ac2053%3A0xb12ec446155f4102!2zVMOyYSBEIENodW5nIGPGsCBCw6FvIE5ow6JuIETDom4gWHXDom4gUGjGsMahbmc!5e0!3m2!1svi!2s!4v1715136328017!5m2!1svi!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
      }
    ]
  },

  {
    _id: 2,
    city: 'Trung tâm bảo hành số 2',
    slug: 'ho-chi-minh',
    store: [
      {
        address: '55 Minh Phụng, Tân Thới Nhất, Quận 12, Thành phố Hồ Chí Minh',
        link: '/chi-nhanh/ho-chi-minh',
        map: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d233.68205364115826!2d106.17841643213313!3d20.427666931231816!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135e78896ae4b57%3A0xe9d749a8e8d956ea!2sGiga%20Digital!5e0!3m2!1svi!2s!4v1666583049627!5m2!1svi!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
      }
    ]
  },
  {
    _id: 3,
    city: 'Trung tâm bảo hành số 3 (Chỉ áp dụng cho Robot Ecovacs)',
    slug: 'da-nang',
    store: [
      {
        address: '35 Chu Mạnh Trinh, Khuê Trung, Cẩm Lệ, Đà Nẵng',
        link: '/chi-nhanh/da-nang',
        map: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3834.596930296155!2d108.20823588469642!3d16.034484500779126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x314219e24c4af535%3A0x7d4c2e80e4dedb83!2zQ8O0bmcgdHkgQ-G7lSBwaOG6p24gQ8O0bmcgbmdo4buHIEjhu6NwIExvbmc!5e0!3m2!1svi!2s!4v1709880460955!5m2!1svi!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
      }
    ]
  },

]


const TramBaoHanh = () => {

  const { slug } = useParams()

  const [address, setAddress] = useState(addressStore[0].store[0])

  const [cityBranch, setCityBranch] = useState(addressStore[0])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    findIndex(addressStore, function (item) {
      if (item.slug == slug) {
        setCityBranch(item)
      }
    })
  }, [slug])

  useEffect(() => {
    setAddress(cityBranch.store[0])
  }, [cityBranch])

  return (
    <div className="container tram-bao-hanh">
      <div className="title text-center">
        <h6 className="py-3 mb-0"><strong>Trung tâm bảo hành</strong></h6>
      </div>
      <div className="content">
        <div className="content-info">
        {
          addressStore.map((item, index) => (
            <div key={index} className='d-block item-list-map'>
              <p><strong >{item.city}</strong></p>
              {item.store.map((element, index1) => (
                <div className='d-flex align-items-center justify-content-between'>
                  <p key={index1} ><i className="fa fa-map-marker mr-10 float-left text-primary"></i><span className="d-flex" dangerouslySetInnerHTML={{ __html: element.address.replace(/\n/g, "<br />") }}></span></p>
                  <button type="button" className="border-0 bg-transparent text-primary" onClick={() => setAddress(element)}>Xem bản đồ</button>
                </div>
              ))}
            </div>
          ))
        }
        </div>

             <div className="vertical-line"></div>
             <div className="content-map">
          {
            address != '' &&
            <p>
              {
                ReactHtmlParser(address && address.map)
              }
            </p>
          }
        </div>
      </div>
    </div>
  )
}

export default TramBaoHanh