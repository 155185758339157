import React from 'react'
import { Link } from 'react-router-dom'
import ScrollAnimation from 'react-animate-on-scroll'

//image xanh
import ImgKichHoat from '../../assets/image/action/h-kich-hoat-1.png'
import ImgHuongDan from '../../assets/image/action/h-huong-dan-1.png'
import ImgTraCuu from '../../assets/image/action/h-tra-cuu-1.png'
import ImgTram from '../../assets/image/action/h-tram-1.png'
import ImgYeuCau from '../../assets/image/action/h-yeu-cau-1.png'

//image trắng
import ImgKichHoatWhite from '../../assets/image/action/h-kich-hoat-at.png'
import ImgHuongDanWhite from '../../assets/image/action/h-huong-dan-at.png'
import ImgTraCuuWhite from '../../assets/image/action/h-tra-cuu-at.png'
import ImgTramWhite from '../../assets/image/action/h-tram-at.png'
import ImgYeuCauWhite from '../../assets/image/action/h-yeu-cau-at.png'

const CheckBaoHanh = () => {

  return (
    <div className='giga-electric'>
      <div className='bg-white info'>
        <div className='header position-relative'>
          <div className='d-flex justify-content-center text-banner'>
            <ScrollAnimation
              animateIn="bounceInLeft"
              animateOut="bounceInRight"
              duration={1.5}
              delay={0}
            >
              <label className='text-center  fw-bolder name-title' style={{ color: '#27BDDE' }}>
                GIGA
              </label>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="bounceInDown"
              animateOut="bounceInUp"
              duration={1.5}
              delay={0}
            >
              <label className='border-vertical' style={{ color: '#27BDDE' }}></label>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="bounceInRight"
              animateOut="bounceInLeft"
              duration={1.5}
              delay={0}
            >
              <label className='text-primary fw-bolder name-title'>digital</label>
            </ScrollAnimation>
          </div>
        </div>
        <div className='bg-giga text-white p-2 d-flex justify-content-around'>
          <div className='header-top'>
            <i className='fa fa-clock-o mr-5 float-left' ></i>
            <p className='d-flex'>Bảo hành tiêu chuẩn nhà sản xuất</p>
          </div>
          <div className='header-top'>
            <i className='fa fa-calendar-o mr-5 float-left' ></i>
            <p className='d-flex'>Đổi mới nếu lỗi nhà sản xuất</p>
          </div>
          <div className='header-top'>
            <i className='fa fa-truck mr-5 float-left' ></i>
            <p className='d-flex'>Liên hệ hotline: 0966.061.170</p>
          </div>
        </div>
        <div className='container'>
          <div className='list-items-button mb-5'>
            <Link to={'/kich-hoat-bao-hanh'} className='item-action d-table'>
              <div className='box-content d-table-cell align-middle'>
                <img src={ImgKichHoat} />
                <img src={ImgKichHoatWhite} className='img-2' />
                <p className='text-black fw-bold mt-2'>Kích hoạt bảo hành</p>
              </div>
            </Link>

            <Link to={'/thoi-han-bao-hanh'} className='item-action d-table'>
              <div className='box-content d-table-cell align-middle'>
                <img src={ImgYeuCau} />
                <img src={ImgYeuCauWhite} className='img-2' />
                <p className='text-black fw-bold mt-2'>Tra cứu thời hạn bảo hành</p>
              </div>
            </Link>

            <Link to={'/gui-yeu-cau'} className='item-action d-table'>
              <div className='box-content d-table-cell align-middle'>
                <img src={ImgYeuCau} />
                <img src={ImgYeuCauWhite} className='img-2' />
                <p className='text-black fw-bold mt-2'>Tạo phiếu yêu cầu bảo hành</p>
              </div>
            </Link>

            <Link to={'/chinh-sach-bao-hanh'} className='item-action d-table'>
              <div className='box-content d-table-cell align-middle'>
                <img src={ImgHuongDan} />
                <img src={ImgHuongDanWhite} className='img-2' />
                <p className='text-black fw-bold mt-2'>Chính sách bảo hành</p>
              </div>
            </Link>

            <Link to={'/khuyen-cao-su-dung'} className='item-action d-table'>
              <div className='box-content d-table-cell align-middle'>
                <img src={ImgHuongDan} />
                <img src={ImgHuongDanWhite} className='img-2' />
                <p className='text-black fw-bold mt-2'>Khuyến cáo khi sử dụng Robot hút bụi</p>
              </div>
            </Link>

            <Link to={'/tra-cuu-bao-hanh'} className='item-action d-table'>
              <div className='box-content d-table-cell align-middle'>
                <img src={ImgTraCuu} />
                <img src={ImgTraCuuWhite} className='img-2' />
                <p className='text-black fw-bold mt-2'>Tra cứu phiếu yêu cầu bảo hành</p>
              </div>
            </Link>

          </div>
          <div className='content-text text-center mb-4'>
            <p><strong>Đối với Quý khách hàng, chúng tôi luôn mang đến những sản phẩm với chất lượng tốt nhất.</strong></p>
            <p><strong>Cùng với đó, sự quan tâm hàng đầu của chúng tôi là đặc biệt chú trọng đến dịch vụ chăm sóc, bảo hành trong suốt vòng đời sản phẩm.</strong></p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CheckBaoHanh